import { useRootCategory as useCategory } from '@emico/category-graphql'

import { useCachedRootCategory } from './fetchCategoryList'

export function useRootCategory() {
  const { data: cachedRootCategory } = useCachedRootCategory(2)
  const { data: fetchedRootCategory } = useCategory()

  const rootCategory = cachedRootCategory ?? fetchedRootCategory

  return rootCategory
}
