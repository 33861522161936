import {
  TweakwiseSuggestionProductsResultFragment,
  useTweakwiseSuggestionProducts,
} from '@emico-hooks/tweakwise-suggestion-products'
import {
  TweakwiseSuggestionsResultFragment,
  useTweakwiseSuggestions,
} from '@emico-hooks/tweakwise-suggestions'
import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { NextRouter, useRouter } from 'next/router'
import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { minWidth } from '@emico/styles'

import CrossIcon from '../icons/CrossIcon'
import SearchIcon from '../icons/SearchIcon'
import { routes } from '../lib/routes'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import Input, { StyledBaseInput } from './Input'
import SearchSuggestionsModal from './SearchSuggestionsModal'

const FormWrapper = styled.div`
  position: relative;

  @media ${minWidth('lg')} {
    position: initial;
  }
`

const StyledInput = styled(Input)`
  background-color: ${theme.colors.backgroundLight};
  font-size: ${theme.fontSizes.sm};
  line-height: ${theme.lineHeights.xs};
  border-radius: ${theme.borderRadius.base};

  ${StyledBaseInput} {
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    background-color: ${theme.colors.backgroundLight};
    border-radius: ${theme.borderRadius.base};
    color: ${theme.colors.grayBrown};
    height: ${theme.sizes.searchInputHeight};
  }
` as typeof Input

const StyledCrossIcon = styled(CrossIcon)`
  font-size: 12px;
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 16px;
`

type TweakwiseSuggestionsResult = Exclude<
  TweakwiseSuggestionsResultFragment['suggestions'],
  null
>

interface RouterQuery extends NextRouter {
  query: {
    query?: string
  }
}

interface FormValues {
  searchQuery: string
}

interface Props extends DefaultLayoutStaticData {
  rootCategory: CategoryFragmentWithChildren
  /**
   * Pass a placeholder if it should differ from default
   */
  placeholder?: string
  /**
   * Does it concern a simple mobile lay-out?
   */
  isSimpleMobile?: boolean
  /**
   * Should Tweakwise suggestions be visible on search?
   */
  showTweakwiseSuggestions?: boolean
  handleClose?: () => void
}

const SearchForm = ({
  websiteData,
  rootCategory,
  placeholder,
  handleClose,
  showTweakwiseSuggestions = true,
  isSimpleMobile = false,
  ...other
}: Props) => {
  const { query: routerQuery, push }: RouterQuery = useRouter()
  const getTweakwiseSuggestions = useTweakwiseSuggestions({
    categoryId: rootCategory.tweakwiseItemNumber ?? '',
  })

  const getTweakwiseSuggestionProducts = useTweakwiseSuggestionProducts({
    categoryId: rootCategory.tweakwiseItemNumber ?? '',
  })

  const [error, setError] = useState<Error | undefined>(undefined)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [tweakwiseSuggestions, setTweakwiseSuggestions] = useState<
    TweakwiseSuggestionsResult | undefined
  >(undefined)

  const [tweakwiseSuggestionProducts, setTweakwiseSuggestionProducts] =
    useState<TweakwiseSuggestionProductsResultFragment | undefined>(undefined)

  const [isSuggestionProductsLoading, setIsSuggestionProductsLoading] =
    useState<boolean>(false)

  const queryParam = routerQuery.query

  const { handleSubmit, control, register, watch, setValue, setFocus } =
    useForm<FormValues>({
      defaultValues: {
        searchQuery: queryParam,
      },
    })

  const searchQueryValue = watch('searchQuery')

  /**
   * Handle remove search query
   */
  const handleRemoveSearchQuery = () => {
    setValue('searchQuery', '')
    setFocus('searchQuery')
    setTweakwiseSuggestions(undefined)
    setTweakwiseSuggestionProducts(undefined)
  }

  /**
   * Function to set Tweakwise suggestions based on search query
   */
  const setSuggestions = useCallback(
    async (value: string) => {
      try {
        setIsSuggestionProductsLoading(true)

        const { suggestions } = await getTweakwiseSuggestions(value)

        const { suggestionProducts } = await getTweakwiseSuggestionProducts(
          value,
        )

        setTweakwiseSuggestions(suggestions)
        setTweakwiseSuggestionProducts(suggestionProducts)
      } catch (error) {
        if (error instanceof Error) {
          setError(error)
        }
      } finally {
        setIsSuggestionProductsLoading(false)
      }
    },
    [getTweakwiseSuggestionProducts, getTweakwiseSuggestions],
  )

  /**
   * Function to handle search query input change
   */
  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value

    setValue('searchQuery', value)

    if (value) {
      setShowSuggestions(true)
      setSuggestions(value)
    } else {
      handleRemoveSearchQuery()
    }
  }

  /**
   * Function to close the autocomplete when hitting escape
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setShowSuggestions(false)
    }
  }

  /**
   * Form submit function
   * Redirects to search page with current search query
   *
   * @param values Form values
   */
  const onSubmit = async (values: FormValues) => {
    if (values.searchQuery) {
      setShowSuggestions(false)
      handleClose?.()

      push({
        pathname: routes.search.base,
        query: {
          query: values.searchQuery,
        },
      })
    }
  }

  /**
   * If search query exists:
   * - Set suggestions with query
   * - Set search field value with query
   */
  useEffect(() => {
    if (queryParam) {
      setSuggestions(queryParam)
      setValue('searchQuery', queryParam)
    }
  }, [queryParam, setSuggestions, setValue])

  return (
    <FormWrapper {...other}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledInput
          control={control}
          placeholder={
            placeholder ??
            t({
              message: 'Search product',
            })
          }
          label={
            placeholder ??
            t({
              message: 'Search product',
            })
          }
          showLabel={false}
          {...register('searchQuery')}
          onFocus={() => setShowSuggestions(true)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          append={
            searchQueryValue ? (
              <ButtonUnstyled
                analyticsContext="search"
                analyticsName="remove"
                aria-label={t({ message: 'Remove search' })}
                onPress={handleRemoveSearchQuery}
              >
                <StyledCrossIcon />
              </ButtonUnstyled>
            ) : (
              <ButtonUnstyled
                type="submit"
                analyticsContext="search"
                analyticsName="submit"
                aria-label={t({ message: 'Search' })}
              >
                <StyledSearchIcon />
              </ButtonUnstyled>
            )
          }
        />
      </form>

      {showTweakwiseSuggestions && (
        <SearchSuggestionsModal
          show={showSuggestions}
          close={() => {
            setShowSuggestions(false)
            handleClose?.()
          }}
          handleSuggestionsSubmit={onSubmit}
          websiteData={websiteData}
          searchQueryValue={searchQueryValue}
          rootCategory={rootCategory}
          tweakwiseSuggestions={tweakwiseSuggestions}
          tweakwiseSuggestionProducts={tweakwiseSuggestionProducts}
          isSuggestionProductsLoading={isSuggestionProductsLoading}
          isSimpleMobile={isSimpleMobile}
          suggestionsError={error?.message}
        />
      )}
    </FormWrapper>
  )
}

export default SearchForm
