import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { maxWidth, minWidth } from '@emico/styles'
import { H2 } from '@emico/ui'

import FooterMainLinks from '../FooterMainLinks'
import FacebookIcon from '../icons/FacebookIcon'
import InstagramIcon from '../icons/InstagramIcon'
import LinkedinIcon from '../icons/LinkedinIcon'
import YoutubeIcon from '../icons/YoutubeIcon'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import Container from './Container'
import CraftLink from './CraftLink'
import FooterDesktop from './FooterDesktop'
import FooterMobile from './FooterMobile'
import { StoreSwitcher } from './StoreSwitcher'

const borderStyling = css`
  border-top: ${theme.borders.default};
  margin-top: ${theme.spacing.xl};
  padding-top: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    margin-top: ${theme.spacing['2xl']};
    padding-top: ${theme.spacing.xl};
  }
`

export const StyledCraftLink = styled(CraftLink)`
  color: ${theme.colors.textLight};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.textLight};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

const FooterWrapper = styled.footer`
  background-color: ${theme.colors.backgroundDark};
  padding: ${theme.spacing['2xl']} 0;

  @media ${minWidth('lg')} {
    padding: ${theme.spacing['3xl']} 0;
  }
`

const Title = styled(H2)`
  margin-bottom: ${theme.spacing.lg};
  color: ${theme.colors.textLight};
  font-weight: ${theme.fontWeights.bold};

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.xl};
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const LinkRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme.spacing.md};

  @media ${minWidth('lg')} {
    ${borderStyling};
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledFooterMainLinks = styled(FooterMainLinks)`
  flex-wrap: wrap;

  @media ${maxWidth('md')} {
    order: 2;
    max-width: ${theme.sizes.cartColumnWidth};
  }
`

const SocialLinksList = styled(List)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
  @media ${minWidth('lg')} {
    margin-left: auto;
  }
`

const SocialCraftLink = styled(CraftLink)`
  display: flex;
  color: ${theme.colors.grayMiddle};
  font-size: 22px;
  border-radius: 50%;
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(196, 196, 196, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(196, 196, 196, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(196, 196, 196, 0);
    }
  }

  &:hover {
    color: ${theme.colors.grayMiddle};
  }

  @media ${minWidth('lg')} {
    &:hover {
      animation-name: pulse;
      animation-duration: 2s;
      animation-timing-function: ${theme.transition.timingFunctions
        .cubicBezierSmooth};
      color: ${theme.colors.white};
    }
  }
`

const StyledYoutubeIcon = styled(YoutubeIcon)`
  font-size: 32px;
`

const StyledStoreSwitcher = styled(StoreSwitcher)`
  order: 1;
  margin-top: 20px;
  display: flex;

  @media ${minWidth('lg')} {
    order: 0;
    margin-top: 0;
  }
`

const FlexAlignOrderContainer = styled.div`
  order: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: ${theme.borders.default};
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.md};

  @media ${minWidth('lg')} {
    order: 0;
    margin-top: 0;
    flex-direction: row;
    border-top: none;
  }
`

type Props = DefaultLayoutStaticData

const Footer = ({ websiteData, ...other }: Props) => {
  const footerGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftFooterGlobalSet',
  )

  const contactGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftContactGlobalGlobalSet',
  )

  const socialLinks =
    contactGlobalSet?.__typename === 'CraftContactGlobalGlobalSet' &&
    contactGlobalSet.siteGlobalContact?.[0]

  if (
    footerGlobalSet?.__typename !== 'CraftFooterGlobalSet' ||
    !footerGlobalSet.linkGroups
  ) {
    return null
  }

  return (
    <FooterWrapper {...other}>
      <Container>
        {footerGlobalSet.title && <Title>{footerGlobalSet.title}</Title>}

        <nav>
          <FooterDesktop linkGroups={footerGlobalSet.linkGroups} />

          <FooterMobile linkGroups={footerGlobalSet.linkGroups} />

          {(socialLinks ||
            footerGlobalSet.copyright ||
            footerGlobalSet.footerLinks?.length !== 0) && (
            <LinkRow>
              <FlexAlignOrderContainer>
                <StyledStoreSwitcher />
                <StyledFooterMainLinks footerGlobalSet={footerGlobalSet} />
              </FlexAlignOrderContainer>

              {socialLinks && (
                <SocialLinksList>
                  {socialLinks.instagram && (
                    <li>
                      <SocialCraftLink
                        analyticsContext="footer"
                        analyticsName="instagram"
                        craftLink={socialLinks.instagram}
                        ariaLabel="Instagram"
                      >
                        <InstagramIcon />
                      </SocialCraftLink>
                    </li>
                  )}

                  {socialLinks.facebook && (
                    <li>
                      <SocialCraftLink
                        analyticsContext="footer"
                        analyticsName="facebook"
                        craftLink={socialLinks.facebook}
                        ariaLabel="Facebook"
                      >
                        <FacebookIcon />
                      </SocialCraftLink>
                    </li>
                  )}

                  {socialLinks.youtube && (
                    <li>
                      <SocialCraftLink
                        analyticsContext="footer"
                        analyticsName="youtube"
                        craftLink={socialLinks.youtube}
                        ariaLabel="Youtube"
                      >
                        <StyledYoutubeIcon />
                      </SocialCraftLink>
                    </li>
                  )}

                  {socialLinks.linkedin && (
                    <li>
                      <SocialCraftLink
                        analyticsContext="footer"
                        analyticsName="linkedin"
                        craftLink={socialLinks.linkedin}
                        ariaLabel="Linkedin"
                      >
                        <LinkedinIcon />
                      </SocialCraftLink>
                    </li>
                  )}
                </SocialLinksList>
              )}
            </LinkRow>
          )}
        </nav>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
