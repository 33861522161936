import styled from '@emotion/styled'
import { ReactNode } from 'react'

import getSeoPageDescription from '../lib/getSeoPageDescription'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import Footer from './Footer'
import Header from './Header'
import Meta from './Meta'
import MetaCanonical from './MetaCanonical'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Main = styled('main', {
  shouldForwardProp: (prop) =>
    !['hasPaddingBottom', 'hasPaddingTop'].includes(prop.toString()),
})<{ hasPaddingBottom: boolean; hasPaddingTop: boolean }>`
  padding-bottom: ${({ hasPaddingBottom }) =>
    hasPaddingBottom && theme.pagePadding};
  padding-top: ${({ hasPaddingTop }) => hasPaddingTop && theme.pagePadding};
`

const StyledFooter = styled(Footer)`
  // If content has less height than viewport, footer should be at bottom
  margin-top: auto;
`

interface Props extends DefaultLayoutStaticData {
  children: ReactNode
  hasPaddingBottom?: boolean
  hasPaddingTop?: boolean
  metaTitle?: string
  metaDescription?: string
  showMetaCanonical?: boolean
}

const DefaultLayout = ({
  websiteData,
  children,
  hasPaddingBottom = true,
  hasPaddingTop = true,
  metaTitle,
  metaDescription,
  showMetaCanonical,
  ...other
}: Props) => (
  <>
    {metaTitle && (
      <Meta
        metaTitle={metaTitle}
        metaDescription={metaDescription ?? getSeoPageDescription()}
      />
    )}

    {showMetaCanonical && <MetaCanonical />}

    <Layout {...other}>
      <Header websiteData={websiteData} />

      <Main hasPaddingBottom={hasPaddingBottom} hasPaddingTop={hasPaddingTop}>
        {children}
      </Main>

      <StyledFooter websiteData={websiteData} />
    </Layout>
  </>
)

export default DefaultLayout
