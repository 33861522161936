import React from 'react'

import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import { useRootCategory } from '../lib/useRootCategory'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import UspBar from './UspBar'

const Header = ({ websiteData }: DefaultLayoutStaticData) => {
  const rootCategory = useRootCategory()

  return (
    // No wrapping element should be used, to maintain sticky header styling
    <>
      <UspBar websiteData={websiteData} color="grayDark" />
      <HeaderDesktop websiteData={websiteData} rootCategory={rootCategory} />
      <HeaderMobile websiteData={websiteData} rootCategory={rootCategory} />
    </>
  )
}

export default Header
