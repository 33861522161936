import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { Maybe } from '@emico/graphql-schema-types'
import { ButtonUnstyled } from '@emico/ui'

import ChevronRightIcon from '../icons/ChevronRightIcon'
import { CraftMenuItemFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import MobileMenuTransition from './MobileMenuTransition'

const ListItem = styled.li`
  margin: 0 ${theme.spacing.md};
`

const MenuButton = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.md} 0;
  border-bottom: ${theme.borders.default};
  font-weight: ${theme.fontWeights.bold};
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ isActive }) =>
    isActive ? theme.colors.primary : theme.colors.text};
  width: 100%;

  &:hover {
    text-decoration: none;
    color: currentColor;
  }
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  font-size: 14px;
`

interface Props {
  category: CategoryFragmentWithChildren
  categoryIsActive: boolean
  handleCategoryClick: (category: CategoryFragmentWithChildren) => void
  submenuItem: Maybe<CraftMenuItemFragment>
  close: () => void
  back: () => void
  isSubOpen: boolean
  isSubActive: boolean
  isMenuOpen: boolean
}

const MobileMenuItemWithChildren = ({
  category,
  categoryIsActive,
  handleCategoryClick,
  submenuItem,
  close,
  back,
  isSubOpen,
  isSubActive,
  isMenuOpen,
}: Props) => (
  <ListItem>
    <MenuButton
      analyticsContext="header.mobile.mainLinks"
      analyticsName={category.name}
      isActive={categoryIsActive}
      onClick={() => handleCategoryClick(category)}
      aria-label={t({ message: 'Menu category' })}
    >
      {category.name}

      <StyledChevronRightIcon />
    </MenuButton>

    <MobileMenuTransition
      submenuItem={
        submenuItem?.__typename === 'CraftMenuItemEntry' ? submenuItem : null
      }
      close={close}
      isMenuOpen={isMenuOpen}
      isSubOpen={isSubOpen}
      isSubActive={isSubActive}
      back={back}
    />
  </ListItem>
)

export default MobileMenuItemWithChildren
