import { useIsLoggedIn } from '@emico-hooks/login-token'
import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { ProductFragment } from '@emico-hooks/product-fragment'
import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import React from 'react'

import { WishlistItemInterface } from '@emico/graphql-schema-types'
import { minWidth } from '@emico/styles'

import HeartIconFill from '../icons/HeartIconFill'
import { routes } from '../lib/routes'
import { useManageWishlistItem } from '../lib/useManageWishlistItem'
import theme from '../theme'

export const StyledHeartIcon = styled(HeartIconFill, {
  shouldForwardProp: (props) => !['hasFill'].includes(props.toString()),
})<{ hasFill: boolean }>`
  font-size: 24px;
  cursor: pointer;
  color: ${theme.colors.grayDark};
  flex-grow: 1;

  & path:last-child {
    transform-origin: center;
    transform: ${({ hasFill }) => `scale(${hasFill ? 1 : 0})`};
    transition-property: transform;
    transition-duration: ${theme.transition.durations.fast};
  }
`

export const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (props) => !['asButton'].includes(props.toString()),
})<{ asButton?: boolean }>`
  width: auto;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: ${theme.spacing.xs};
  border-width: 1px;
  border-color: ${theme.colors.grayDark};
  border-radius: ${theme.borderRadius.base};
  border: ${({ asButton }) => (asButton ? theme.borders.default : 'none')};

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover('currentColor', '1px', 'span')};
  }
`

interface ToggleWishlistItem {
  (): void
}
interface Props {
  wishlistId: string
  wishlistItem: WishlistItemInterface | undefined | null
  product: ProductFragment | ProductCardFragment
  asButton?: boolean
  analyticsContext?: string
  analyticsName?: string
  children?: (
    toggleWishlistItem: ToggleWishlistItem,
    isWishlistItem: boolean,
  ) => JSX.Element
}

export const WishlistIconButton = ({
  wishlistId,
  wishlistItem = undefined,
  product,
  asButton = false,
  children,
  ...other
}: Props) => {
  const isLoggedIn = useIsLoggedIn()

  const { toggleWishlistItem } = useManageWishlistItem()
  const { push, asPath } = useRouter()
  const handleOnToggleWishlistItem = () => {
    if (!isLoggedIn) {
      push({
        pathname: routes.account.login,
        query: {
          flow: asPath,
        },
      })
    } else {
      toggleWishlistItem(wishlistId, wishlistItem, product)
    }
  }

  // render custom or the default wishlist iconbutton
  return children && typeof children === 'function' ? (
    children(handleOnToggleWishlistItem, Boolean(wishlistItem))
  ) : (
    <StyledButtonUnstyled
      asButton={asButton}
      type="button"
      {...other}
      analyticsContext={other?.analyticsContext ?? ''}
      analyticsName={other?.analyticsName ?? ''}
      onPress={handleOnToggleWishlistItem}
      aria-label={t({ message: 'Wishlist' })}
    >
      <StyledHeartIcon hasFill={Boolean(wishlistItem)} />
    </StyledButtonUnstyled>
  )
}
