import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React, { useState } from 'react'

import { CategoryFragmentWithChildren } from '@emico/category-graphql'
import { SearchIcon, ShoppingCartIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'
import { ButtonUnstyled, Container, Logo } from '@emico/ui'

import AccountIcon from '../icons/AccountIcon'
import BarsIcon from '../icons/BarsIcon'
import HeartIcon from '../icons/HeartIcon'
import { routes } from '../lib/routes'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import theme from '../theme'
import CartQuantity from './CartQuantity'
import MenuMobileModal from './MenuMobileModal'
import NextLink from './NextLink'
import SearchMobileModal from './SearchMobileModal'
import StoreSelectorModal from './StoreSelectorModal'

const Header = styled.header`
  position: sticky;
  top: 0;
  background-color: ${theme.colors.background};
  border-bottom: ${theme.borders.default};
  z-index: ${theme.zIndices.navbar};

  @media ${minWidth('lg')} {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${theme.sizes.headerHeight};
`

const StyledNextLink = styled(NextLink)`
  color: currentColor;

  &:hover {
    color: currentColor;
  }
`

const IconWrapper = styled.div`
  position: relative;
`

const MenuButton = styled(ButtonUnstyled)`
  margin-right: ${theme.spacing.md};
`

const StyledBarsIcon = styled(BarsIcon)`
  font-size: 17px;
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 17px;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.lg};
`

const StyledAccountIcon = styled(AccountIcon)`
  font-size: 16px;
`

const StyledHeartIcon = styled(HeartIcon)`
  font-size: 17px;
`

const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
  font-size: 19px;
`

interface Props extends DefaultLayoutStaticData {
  rootCategory: CategoryFragmentWithChildren
}

const HeaderMobile = ({ ...other }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false)
  const [openStoreSelectorModal, setOpenStoreSelectorModal] =
    useState<boolean>(false)

  return (
    <Header>
      <Container>
        <Row>
          <div>
            <MenuButton
              analyticsContext="header.mobile"
              analyticsName="openMenu"
              onClick={() => setMenuIsOpen(true)}
              aria-label={t({ message: 'Open mobile menu' })}
            >
              <StyledBarsIcon />
            </MenuButton>

            <NextLink
              href="/"
              ariaLabel={t({ message: 'Homepage' })}
              analyticsContext="header.mobile"
              analyticsName="logo"
            >
              <Logo />
            </NextLink>
          </div>

          <LinkWrapper>
            <ButtonUnstyled
              analyticsContext="header.mobile"
              analyticsName="openSearch"
              onClick={() => setSearchIsOpen(true)}
              aria-label={t({ message: 'Search' })}
            >
              <StyledSearchIcon />
            </ButtonUnstyled>

            <StyledNextLink
              href={routes.account.orderHistory.base}
              analyticsContext="header.mobile"
              analyticsName="account"
              ariaLabel={t({ message: 'Account' })}
            >
              <StyledAccountIcon />
            </StyledNextLink>

            <StyledNextLink
              href={routes.wishlist}
              analyticsContext="header.mobile"
              analyticsName="favorites"
              ariaLabel={t({ message: 'Wishlist' })}
            >
              <StyledHeartIcon />
            </StyledNextLink>

            <StyledNextLink
              href="/cart"
              analyticsContext="header.mobile"
              analyticsName="cart"
              ariaLabel={t({ message: 'Cart' })}
            >
              <IconWrapper>
                <StyledShoppingCartIcon />

                <CartQuantity />
              </IconWrapper>
            </StyledNextLink>
          </LinkWrapper>
        </Row>
      </Container>

      <MenuMobileModal
        show={menuIsOpen}
        close={() => setMenuIsOpen(false)}
        setOpenStoreSelectorModal={setOpenStoreSelectorModal}
        {...other}
      />

      <SearchMobileModal
        show={searchIsOpen}
        close={() => setSearchIsOpen(false)}
        {...other}
      />

      <StoreSelectorModal
        show={openStoreSelectorModal}
        close={() => setOpenStoreSelectorModal(false)}
      />
    </Header>
  )
}

export default HeaderMobile
